import mainjson from './main';
import { Level } from '../utils/LoginFunctions';
import {DisplayDateTimeInput} from "../components/form/fields/DateTimeInput";

function checkIf(data) {
    if (parseInt(Level()) <= 0) {
        return true;
    }
    else {
        return false;
    }
}

function filterTypes(array,data){
    let returnArray = [];
    let id = data.assigned_ticket_date_id;
    for (let i = 0; i < array.length; i++) {
        let record = array[i];
        if (record.extra.date_id.toString() == id) {
            returnArray.push(record);   
        }
    }

    return returnArray;
}

function parseDateTimes(val,fields) {
    val = DisplayDateTimeInput(val,fields);
    return val;
}




export default function GetData() {
        let data = {
        "apitype":"ordertickets",
        "subidname":"assigned_ticket_id",
        "id":"assigned_ticket_id",
        "options":["edit","insert","delete"],
        "nameSingle":"ticket",
        "nameMultiple":"tickets",
        "fields":{
            "field0":{
                "name":"ID",
                "field":"assigned_ticket_id",
                "type":"Display",
                "list":true,
                "edit":false,
            }, 
            "field1":{
                "name":"Date",
                "field":"assigned_ticket_date_id",
                "type":"DropDown",
                "options": "dates",
                "list":true,
                "parseValues":parseDateTimes
            }, 
            "field2b":{
                "name":"Ticket Type",
                "field":"assigned_ticket_date_tickettype_id",
                "type":"DropDown",
                "options": "tickettypes",
                "list":true,
                "filter":filterTypes
                
            }, 
            "field3":{
                "name":"Used",
                "field":"assigned_ticket_used",
                "type":"DropDown",
                "options": {
                    "0":"No",
                    "1":"Yes",
                },
                "edit":true,
                "list":true,
            },     
        }
    }

    return data;
}